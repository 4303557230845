import React from 'react'
import "./Sidebar.css"
import { useNavigate } from 'react-router-dom'

export default function Sidebar() {
    const navigate = useNavigate();
    return (
        <div className="ResumeSidebar">
            <div className="ResumeSidebarSticky container">
                <button type="button" className="ResumeSidebarH1" onClick={() => navigate("/")}>
                    <h1>Daichi Sakai's Portfolio</h1>
                </button>
                <ul className="ResumeSidebarUl">
                    <li><button type="button" className="ResumeSidebarHome" onClick={() => navigate("/")}><p>Home</p></button></li>
                    <li><button type="button" className="ResumeSidebarHome" onClick={() => navigate("/blog")}><p>Blog</p></button></li>
                    <li><a href="https://github.com/uonoko1" target="_blank" rel="noopener noreferrer"><p>GitHub</p></a></li>
                    <li><a href="https://staging.livegt.net" target="_blank" rel="noopener noreferrer"><p>LiveGT(staging)</p></a></li>
                </ul>
            </div>
        </div>
    )
}
