import React from 'react'
import "./BlogLayout.css";
import { Outlet } from "react-router-dom";
import Sidebar from '../../components/sidebar/Sidebar';

export default function BlogLayout() {
    return (
        <div className="BlogWrapper">
            <Sidebar />
            <div className="BlogContainer container">
                <Outlet /> {/* ここで子ルートがレンダリングされます */}
            </div>
            <div className="BlogRightbar">
                <div className="BlogRightbarSticky container">
                    {/* 広告表示エリア */}
                </div>
            </div>
        </div>
    );
}
