import React from 'react';

const Description = ({ description }) => {
    const lines = description.split('\n');
    let inList = false;
    let inP = false;
    let inCodeBlock = false;
    let currentItem = [];
    const content = [];
    let listStack = [];  // ネストされたリストを管理
    let pItems = [];
    let codeBlockItems = [];

    const renderTextWithLinksAndCode = (text, index) => {
        const tldList = 'com|net|org|io|jp';
        const urlPattern = new RegExp(`(https?:\\/\\/)?([a-z0-9][a-z0-9\\-]*[a-z0-9]\\.)+(?:${tldList})(\\/[a-z0-9\\.\\-\\$&%/:=#~]*)?`, 'gi');
        const markdownLinkPattern = /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/gi; // マークダウンリンクの正規表現
        const markdownImagePattern = /!\[([^\]]*)\]\(([^)]+)\)/gi; // マークダウン画像の正規表現

        const segments = [];
        let lastIndex = 0;
        let match;

        // マークダウン画像を検出して変換
        while ((match = markdownImagePattern.exec(text)) !== null) {
            const preText = text.slice(lastIndex, match.index);
            segments.push(...processTextForInlineCode(preText));
            lastIndex = match.index + match[0].length;

            segments.push(<img src={match[2]} alt={match[1]} key={`img-${index}-${lastIndex}`} style={{ maxWidth: '100%' }} />);
        }

        const remainingTextAfterImage = text.slice(lastIndex);
        lastIndex = 0;
        while ((match = markdownLinkPattern.exec(remainingTextAfterImage)) !== null) {
            const preText = remainingTextAfterImage.slice(lastIndex, match.index);
            segments.push(...processTextForInlineCode(preText));
            lastIndex = match.index + match[0].length;

            segments.push(<a href={match[2]} target="_blank" rel="noopener noreferrer" key={`link-${index}-${lastIndex}`}>{match[1]}</a>);
        }

        const remainingTextAfterMarkdownLinks = remainingTextAfterImage.slice(lastIndex);
        let tempIndex = 0;
        while ((match = urlPattern.exec(remainingTextAfterMarkdownLinks)) !== null) {
            const preText = remainingTextAfterMarkdownLinks.slice(tempIndex, match.index);
            segments.push(...processTextForInlineCode(preText, lastIndex + tempIndex));
            tempIndex = match.index + match[0].length;

            const url = match[0].startsWith('http') ? match[0] : `https://${match[0]}`;
            segments.push(<a href={url} target="_blank" rel="noopener noreferrer" key={`link-${index}-${lastIndex + tempIndex}`}>{match[0]}</a>);
        }

        const finalRemainingText = remainingTextAfterMarkdownLinks.slice(tempIndex);
        if (finalRemainingText) {
            segments.push(...processTextForInlineCode(finalRemainingText, lastIndex + tempIndex));
        }

        return segments;
    };

    const processTextForInlineCode = (text) => {
        const segments = [];
        let lastIndex = 0;
        let match;
        const codePattern = /`([^`]+)`/g;

        while ((match = codePattern.exec(text)) !== null) {
            if (match.index > lastIndex) {
                segments.push(text.slice(lastIndex, match.index));
            }

            segments.push(<code key={`code-${lastIndex}`}>{match[1]}</code>);
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < text.length) {
            segments.push(text.slice(lastIndex));
        }

        return segments;
    };

    const getIndentLevel = (line) => {
        return line.match(/^\s*/)[0].length;
    };

    lines.forEach((line, index) => {
        const indentLevel = getIndentLevel(line);

        if (line.trim().startsWith('- ')) {
            if (!inList) {
                inList = true;
                listStack.push({ items: [], indentLevel });
            }

            const currentList = listStack[listStack.length - 1];
            if (indentLevel > currentList.indentLevel) {
                const newList = { items: [], indentLevel };
                listStack.push(newList);
            } else {
                while (listStack.length > 1 && indentLevel < listStack[listStack.length - 1].indentLevel) {
                    const lastList = listStack.pop();
                    listStack[listStack.length - 1].items.push(<ul key={`ul-${index}`}>{lastList.items}</ul>);
                }
            }

            const lineContent = renderTextWithLinksAndCode(line.trim().substring(2), index);
            listStack[listStack.length - 1].items.push(<li key={`li-${index}`}>{lineContent}</li>);
        } else if (line.startsWith("```")) {
            if (!inCodeBlock) {
                inCodeBlock = true;
                codeBlockItems = [];
            } else {
                inCodeBlock = false;
                content.push(<pre key={`pre-${index}`}><code>{codeBlockItems.join('\n')}</code></pre>);
                codeBlockItems = [];
            }
        } else if (inList) {
            if (line.trim() === '') {
                while (listStack.length > 1) {
                    const lastList = listStack.pop();
                    listStack[listStack.length - 1].items.push(<ul key={`ul-${index}`}>{lastList.items}</ul>);
                }
                const currentList = listStack.pop();
                content.push(<ul key={`ul-${index}`}>{currentList.items}</ul>);
                inList = false;
            }
        } else if (inP) {
            if (line.trim() === '') {
                inP = false;
                content.push(<p key={`p-${index}`}>{pItems}</p>);
                content.push(<br key={`br-${index}`} />);
                currentItem = [];
            } else {
                pItems.push(<br key={`br-${index}`} />);
                const lineContent = renderTextWithLinksAndCode(line, index);
                pItems.push(<span key={`p-${index}`}>{lineContent}</span>);
            }
        } else if (line.startsWith('# ')) {
            content.push(<h1 key={`h1-${index}`}>{line.substring(2)}</h1>);
        } else if (line.startsWith('## ')) {
            content.push(<h2 key={`h2-${index}`}>{line.substring(3)}</h2>);
        } else if (line.startsWith('### ')) {
            content.push(<h3 key={`h3-${index}`}>{line.substring(4)}</h3>);
        } else {
            if (inCodeBlock) {
                codeBlockItems.push(line);
            } else {
                const lineContent = renderTextWithLinksAndCode(line, index);
                if (!inP) {
                    inP = true;
                    pItems = [];
                }

                pItems.push(<span key={`p-${index}`}>{lineContent}</span>);
            }
        }
    });

    while (listStack.length > 1) {
        const lastList = listStack.pop();
        listStack[listStack.length - 1].items.push(<ul key={`ul-last`}>{lastList.items}</ul>);
    }

    if (inList && currentItem.length > 0) {
        const currentList = listStack.pop();
        content.push(<ul key={`ul-last`}>{currentList.items}</ul>);
    }

    if (inP && pItems.length > 0) {
        content.push(<p key={`p-last`}>{pItems}</p>);
    }

    return <div className='Description'>{content}</div>;
};

export default Description;
