import React, { useEffect, useState } from 'react'
import "./Blog.css";
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { useClickContext } from '../../state/ClickContext';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

export default function Blog() {
    const { articles, setArticles, fetchedArticleIds, setFetchedArticleIds, hasMore, setHasMore, page, setPage } = useClickContext();
    const [displayArticles, setDisplayArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [kwSearch, setKwSearch] = useState(false);
    const navigate = useNavigate();
    let fetched = false;

    const fetchArticles = async () => {
        fetched = true;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog?page=${page}`);
        const fetchData = response.data.filter((article) => !fetchedArticleIds.has(article._id));
        setArticles(prevArticles => [...prevArticles, ...fetchData]);
        setDisplayArticles(prevDisplayArticles => [...prevDisplayArticles, ...fetchData]);
        fetchData.forEach(article =>
            setFetchedArticleIds(prevIds => new Set([...prevIds, article._id])));
        if (fetchData.length < 15) setHasMore(false);
    }

    useEffect(() => {
        try {
            setIsLoading(true);
            if (!fetched && articles.length === 0) fetchArticles();
            setDisplayArticles(articles)
        } catch (err) {
            console.log("err:", err);
        } finally {
            setIsLoading(false);
        }
    }, [])

    const onSearch = async () => {
        if (!searchInput) return;
        try {
            setIsLoading(true);
            if (hasMore) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/blog/search`, {
                    fetchedIds: Array.from(fetchedArticleIds),
                    kw: searchInput
                });
                const fetchData = response.data.filter((article) => !fetchedArticleIds.has(article._id));
                setArticles(prevArticles => [...prevArticles, ...fetchData]);
                fetchData.forEach(article =>
                    setFetchedArticleIds(prevIds => new Set([...prevIds, article._id])));
                console.log("Articles:", fetchData);
            }
            const searchData = articles.filter((article) => article.Title.includes(searchInput) || article.Content.includes(searchInput));
            setDisplayArticles(searchData);
            setKwSearch(true);
        } catch (err) {
            console.log("err:", err);
        } finally {
            setIsLoading(false);
        }
    }

    const clearSearch = () => {
        setDisplayArticles(articles);
        setKwSearch(false);
    }

    const moveArticle = (id) => {
        clearSearch();
        navigate(`/blog/${id}`);
    }

    const moreFetching = () => {
        setPage((prevPage) => prevPage + 1);
    }

    useEffect(() => {
        if (page === 1) return;
        try {
            setIsFetchingMore(true);
            fetchArticles();
        } catch (err) {
            console.log("err:", err);
        } finally {
            setIsFetchingMore(false);
        }
    }, [page])

    return (
        <>
            <div className="BlogContentTitle">
                <h1>Blog</h1>
                <form className="SearchBox" onSubmit={(e) => { e.preventDefault(); onSearch() }}>
                    <label htmlFor="search">
                        <SearchIcon onClick={onSearch} style={{ cursor: 'pointer' }} />
                        <input
                            type="text"
                            id="search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder='検索'
                        />
                        <button type="submit" style={{ display: 'none' }}>検索</button>
                    </label>
                </form>
            </div>
            <div className="BlogContent">
                {kwSearch &&
                    <div className="SearchStatus">
                        <KeyboardBackspaceOutlinedIcon onClick={clearSearch} />
                        <p>{searchInput}</p>
                    </div>}
                {isLoading ?
                    <TailSpin color='#00BFFF' height={30} width={30} />
                    :
                    <div className="Articles">
                        {displayArticles.map((article, index) => (
                            <div key={index} className="Article" onClick={() => moveArticle(article._id)}>
                                <h2>{article.Title}</h2>
                                <p>{article.Content.substring(0, 30)}...</p>
                                {article.Tag && <span className="ArticleTag">{article.Tag}</span>}
                            </div>
                        ))}
                    </div>
                }
                {hasMore &&
                    <div className="HasMoreButtonDiv">
                        {isFetchingMore ?
                            <TailSpin color='#00BFFF' height={30} width={30} />
                            :
                            <button type='button' onClick={moreFetching}><p>記事をもっとみる</p></button>
                        }
                    </div>
                }
            </div>
        </>
    )
}
