import { createContext, useContext, useState, useRef } from "react";


const ClickContext = createContext();

export function useClickContext() {
    return useContext(ClickContext);
}

export function Clickcontextprovider({ children }) {

    const [dataInfo, setDataInfo] = useState("");
    const [modalMenu, setModalMenu] = useState(false);
    const topRef = useRef(null);
    const aboutRef = useRef(null);
    const profileRef = useRef(null);
    const worksRef = useRef(null);
    const contactRef = useRef(null);

    const [articles, setArticles] = useState([]);
    const [fetchedArticleIds, setFetchedArticleIds] = useState(new Set());

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    return (
        <ClickContext.Provider value={{ dataInfo, setDataInfo, topRef, aboutRef, profileRef, worksRef, contactRef, modalMenu, setModalMenu, articles, setArticles, fetchedArticleIds, setFetchedArticleIds, hasMore, setHasMore, page, setPage }}>
            {children}
        </ClickContext.Provider>
    )
}