import React, { useEffect, useState } from 'react'
import "./Article.css";
import Sidebar from '../../components/sidebar/Sidebar';
import Description from '../../utils/Description';
import { useClickContext } from '../../state/ClickContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function Article() {
    const { articles, setArticles } = useClickContext();
    const [finded, setFinded] = useState(false);
    const [article, setArticle] = useState();
    const [nothingArticle, setNothingArticle] = useState(false);
    const articleId = useParams().articleId;

    useEffect(() => {
        setArticle(articles.find((article) => articleId === article._id));
        setFinded(true);
    }, []);
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog/${articleId}`);
                setArticle(response.data);
            } catch {
                setNothingArticle(true);
            }
        }
        if (finded && !article) fetchArticle();
    }, [finded])

    if (!article) {
        return null
    } else {
        const createdAt = new Date(article.createdAt).toLocaleDateString('ja-JP');
        const updatedAt = new Date(article.updatedAt).toLocaleDateString('ja-JP');
        const dateLabel = createdAt === updatedAt ? `作成日: ${createdAt}` : `更新日: ${updatedAt}`;
        return (
            <>
                <div className="BlogContentTop">
                    <div className="BlogContentTitle">
                        <h1>{article.Title}</h1>
                    </div>
                    <time className="ArticleDate" dateTime={article.updatedAt}>{dateLabel}</time>
                </div>
                <div className="BlogContent">
                    <Description description={article.Content} />
                </div>
            </>
        )
    }
}
